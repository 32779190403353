import React from "react";

import PageWrapper from "../components/PageWrapper";

const Contact = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu",
          headerFluid: true,
          footerStyle: "style5",
        }}
      >
        <div className="d-flex align-items-center pt-23 pt-md-26 pt-lg-30 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8 col-12-sm order-1 pr-lg-20">
                <div className="pt-12 pb-10 mx-auto">
                  <iframe
                    id="JotFormIFrame-52367141887867"
                    title="aquipa ~ contact"
                    allowFullScreen={true}
                    allow="geolocation; microphone; camera"
                    src="https://form.jotform.com/202123147341441"
                    frameBorder="0"
                    style={{
                      minWidth: "100%",
                      height: "596px",
                      border: "none",
                    }}
                    scrolling="no"
                  >
                    {" "}
                  </iframe>
                </div>
              </div>
              <div className="dark-mode-texts col-lg-4 col-12-sm mx-8 mx-lg-0 order-2 gradient-background px-12 pt-12 pb-12 align-center rounded-10 shadow-3">
                <h4 style={{ fontWeight: "normal" }}>
                  With FormPigeon you can:
                </h4>
                <ul className="bullet-points">
                  <li>
                    Have unlimited access to a suite of professionally vetted
                    forms
                  </li>
                  <li>Streamline your document and contact management</li>
                  <li>
                    Impress your customers with beautiful industry-leading
                    reports
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
